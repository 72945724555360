// @flow
import { type JSSThemeDefaultHeaderMenuType } from '../JSSThemeDefaultHeaderMenu';

export const JSSThemeHeaderMenu22: JSSThemeDefaultHeaderMenuType = (
  themeVars
) => {
  const {
    margins,
    colors: {
      colorTypes: { white, primary, text2, neutral2, accent },
    },
  } = themeVars;
  return {
    HeaderMenuDesktop: {
      menuWrapper: {
        background: white,
        zIndex: 'auto',
      },
      menuItemWrapper: {
        borderRight: 'none',
      },
      menuItem: {
        color: text2,
        fontWeight: 'bold',
        padding: [0, margins.md],
        '&:hover, &:focus': {
          color: primary,
        },
      },
      menuItemActive: {
        color: primary,
      },
    },
    HeaderMenuMobile: {
      mobileMenu: {
        background: neutral2,
        flexWrap: 'wrap',
      },
      menuItem: {
        borderBottom: 'none',
        '&:hover, &:focus': {
          color: white,
        },
        '&.active': {
          color: accent,
        },
      },
    },
  };
};
