// @flow
import { type JSSThemeDefaultSitemapType } from '../JSSThemeDefaultSitemap';

export const JSSThemeSitemap22: JSSThemeDefaultSitemapType = themeVars => {
  const {
    colors: {
      colorTypes: { text2 },
    },
  } = themeVars;
  return {
    sitemapTitle: {
      color: text2,
    },
    firstLinkLevel: {
      color: text2,
    },
    secondLinkLevel: {
      color: text2,
    },
    thirdLinkLevel: {
      color: ' rgba(19, 19, 19, 0.72)',
      fontSize: 15,
      fontWeight: 'normal',
      listStyle: 'none',
      '&:hover': {
        color: text2,
      },
    },
  };
};
