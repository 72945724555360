// @flow
import { type JSSThemeDefaultLiveType } from '../JSSThemeDefaultLive';

export const JSSThemeLive22: JSSThemeDefaultLiveType = (themeVars) => {
  const {
    margins,
    colors: {
      colorTypes: { black },
    },
  } = themeVars;
  return {
    LiveMenuRegularEvent: {
      liveMenuEventRoot: {
        marginBottom: `${margins.md}px !important`,
      },
    },
    LiveMenuEvent: {
      LiveEventRanked: {
        background: 'white',
        borderRadius: '3px',
      },
      TeamsText: {
        color: black,
      },
    },
  };
};
