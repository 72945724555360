// @flow
import { type JSSThemeDefaultHeaderType } from '../JSSThemeDefaultHeader';

export const JSSThemeHeader22: JSSThemeDefaultHeaderType = (themeVars) => {
  const {
    colors: {
      colorTypes: { white },
    },
  } = themeVars;
  return {
    HeaderDesktop: {
      root: {
        background: white,
      },
      authContentWrap: {
        justifyContent: 'center',
      },
      authWrapper: {
        padding: 0,
      },
    },
    LogoBrand: {
      logoWrapper: {
        width: 350,
        padding: '7px 10px 10px',
        '@media screen and (max-width: 1200px)': {
          width: 200,
        },
      },
      logo: {
        maxWidth: 200,
      },
    },
  };
};
