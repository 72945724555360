// @flow
import { type JSSThemeDefaultOutcomesType } from '../JSSThemeDefaultOutcomes';

export const JSSThemeOutcomes22: JSSThemeDefaultOutcomesType = themeVars => {
  const {
    colors: {
      colorTypes: { border1, background1, white, primary },
    },
  } = themeVars;
  return {
    MobileOutcome: {
      '@global': {
        '@keyframes blinkUp': {
          '0%': { backgroundColor: '#02bd2e' },
          '50%': { backgroundColor: background1 },
          '100%': { backgroundColor: '#02bd2e' },
        },
        '@keyframes blinkDown': {
          '0%': { backgroundColor: '#ff2424' },
          '50%': { backgroundColor: background1 },
          '100%': { backgroundColor: '#ff2424' },
        },
      },
      root: {
        background: border1,
        color: white,
      },
      wideRoot: {
        background: border1,
      },
      rootTrendUp: {
        '&::before': {
          borderColor: background1,
        },
      },
      rootTrendDown: {
        '&::after': {
          borderColor: background1,
        },
      },
      priceValue: {
        color: white,
      },
    },
    Outcome: {
      root: {
        color: '#fff',
        background: background1,
        '&.active': {
          background: primary,
        },
        '@media not all and (pointer: coarse)': {
          '&:hover': {
            background: primary,
          },
        },
      },
    },
  };
};
