// @flow
import { type JSSThemeDefaultSearchType } from '../JSSThemeDefaultSearch';

export const JSSThemeSearch22: JSSThemeDefaultSearchType = themeVars => {
  const {
    colors: {
      colorTypes: { background1, neutral2, text2, primary },
    },
  } = themeVars;
  return {
    SportsbookSearchSuggest: {
      input: {
        background: `${background1} !important`,
      },
    },
    SportsbookSearchResult: {
      title: {
        color: neutral2,
      },
      description: {
        color: text2,
        '&:hover': {
          color: text2,
          opacity: 0.5,
        },
      },
      marketCount: {
        background: 'transparent',
        color: neutral2,
        fontWeight: 'bold',
        '&:hover': {
          color: primary,
        },
      },
    },
  };
};
