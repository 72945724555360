// @flow
import { type JSSThemeDefaultHeaderMobileType } from '../JSSThemeDefaultHeaderMobile';

export const JSSThemeHeaderMobile22: JSSThemeDefaultHeaderMobileType = themeVars => {
  const {
    colors: {
      colorCombinations: { whiteNeutral2 },
      colorTypes: { text4, white, text2 },
    },
  } = themeVars;
  return {
    HeaderMobile: {
      headerContainer: {
        background: text4,
        color: white,
      },
      menuContainer: {
        ...whiteNeutral2,
      },
      logoImg: {
        maxWidth: '140px',
        width: '100%',
        height: 'auto',
      },
      iconContainer: {
        color: text2,
      },
    },
  };
};
