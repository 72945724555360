import { type JSSThemeDefaultSportsMenuType } from '../JSSThemeDefaultSportsMenu';

export const JSSThemeSportsMenu22: JSSThemeDefaultSportsMenuType = themeVars => {
  const {
    margins,
    fontSizes,
    colors: {
      colorTypes: { white, text2, transparent, neutral3 },
      colorCombinations: { whiteBg4, whitePrimary, text2Bg2 },
    },
  } = themeVars;
  return {
    HorizontalSportsListUIBigIcons: {
      list: {
        background: transparent,
      },
      sportLink: {
        color: text2,
        fontWeight: 'bold',
        padding: 0,
      },
    },
    SportsMenuMobile: {
      regions: {
        background: transparent,
        padding: [0, margins.md],
      },
      region: {
        ...text2Bg2,
        fontWeight: 'bold',
        borderRadius: '2px',
        marginRight: margins.md,
      },
    },
    SportsMenuDesktop: {
      header: {
        color: white,
      },
      sport: {
        ...whiteBg4,
        '&:hover, &:focus, &.active': whitePrimary,
      },
      sportTitle: {
        fontWeight: 'bold',
      },
      region: {
        background: neutral3,
        color: white,
      },
    },
    HorizontalSportsListIconSmall: {
      sportName: {
        fontWeight: 'bold',
      },
    },
    SubMenuListUIMobile: {
      heading: {
        fontSize: fontSizes.lg,
        color: white,
        padding: [margins.md, margins.xm, margins.xs],
      },
      link: {
        color: white,
        '&:hover,&:focus': {
          color: text2,
        },
        '&.active': {
          ...whitePrimary,
        },
      },
    },
    HorizontalLiveCasinoIcon: {
      icon: {
        color: white,
        background: text2,
      },
    },
  };
};
