// @flow
import { type JSSThemeDefaultBetslipType } from '../JSSThemeDefaultBetslip';

export const JSSThemeBetslip22: JSSThemeDefaultBetslipType = (themeVars) => {
  const {
    margins,
    borderRadius,
    colors: {
      colorCombinations: { whiteBg4 },
      colorTypes: {
        background3,
        background5,
        neutral1,
        background4,
        border2,
        white,
        background1,
        success,
      },
    },
  } = themeVars;
  return {
    Betslip: {
      root: {
        background: background3,
      },
      info: {},
      wrapTotals: {
        background: background1,
      },
      linkButtons: {
        margin: 0,
        padding: [margins.sm, margins.md],
      },
    },
    BetslipTotals: {
      root: {
        borderTop: 'none',
        borderBottom: `1px solid ${background4}`,
      },
      container: {
        color: white,
      },
    },
    BetslipSelection: {
      common: {
        background: background4,
        borderRadius: borderRadius.lg,
        padding: margins.sm,
        margin: margins.xs,
      },
    },
    NonSingleBody: {
      selectionInfo: {
        color: white,
      },
    },
    TextPair: {
      row: {
        color: border2,
      },
    },
    SingleSelectionInfo: {
      eventDescription: {
        color: white,
      },
      marketDescription: {
        color: border2,
      },
      priceDescription: {
        color: white,
      },
    },
    SingleSelectionStaticInfo: {
      root: {
        padding: margins.md,
        background: background4,
        color: white,
      },
      marketDescription: {
        color: border2,
      },
    },
    BetslipInput: {
      container: {
        borderRadius: 0,
        background: background3,
      },
      label: {
        color: background5,
      },
    },

    EachWayButton: {
      root: {},
      label: {
        '&:after': {
          // border: `3px solid ${text3}`,
        },
        '&:before': {
          // border: `1px solid ${border2}`,
          background: neutral1,
        },
      },
    },
    BetslipErrors: {
      list: {
        ...whiteBg4,
      },
      listItem: {},
    },

    Receipt: {
      root: {
        background: border2,
      },
      controls: {
        background: background1,
      },
      titleContainer: {
        background: success,
      },
    },
    ShareBets: {
      message: {
        '&.shown': {
          color: white,
        },
      },
    },
  };
};
