// @flow
import { type JSSThemeDefaultFormType } from '../JSSThemeDefaultForm';

export const JSSThemeForm22: JSSThemeDefaultFormType = themeVars => {
  const {
    colors: {
      colorCombinations: { text2White },
      colorTypes: { text3, background3 },
    },
    fontSizes,
    borderRadius,
  } = themeVars;
  return {
    Input: {
      root: {
        borderRadius: borderRadius.md,
        border: `1px solid ${background3}`,
        ...text2White,
        '&::placeholder': {
          color: text3,
        },
        height: 36,
        lineHeight: '36px',
      },
      betslipInput: {
        border: 'none',
        borderRadius: 0,
        fontSize: fontSizes.md,
        ...text2White,
      },
    },
  };
};
