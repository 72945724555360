// @flow
import { type JSSThemeDefaultPopularLeaguesType } from '../JSSThemeDefaultPopularLeagues';

export const JSSThemePopularLeagues22: JSSThemeDefaultPopularLeaguesType = themeVars => {
  const {
    colors: {
      colorTypes: { background4, neutral2, white },
    },
    fontSizes,
  } = themeVars;
  return {
    PopularLeagues: {
      root: {
        background: background4,
        '@media (pointer: coarse)': {
          background: neutral2,
        },
      },
      link: {
        '&:active, &:focus': {
          color: white,
        },
      },
      header: {
        fontSize: fontSizes.xxl,
      },
    },
  };
};
