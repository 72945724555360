import { type JSSThemeDefaultAccountSectionType } from '../JSSThemeDefaultAccountSection';

export const JSSThemeAccountSection22: JSSThemeDefaultAccountSectionType = themeVars => {
  return {
    AccountPageDesktop: {
      accountPageRightContent: {
        background: '#fff',
        boxShadow: '3px 0px 10px rgba(0, 0, 0, 0.1)',
      },
    },
  };
};
