// @flow
import {
  type JSSThemeShapeType,
  type JSSThemeVariablesShapeType,
  getColorCombinations,
  type ColorTypes,
  type GetJSSThemePatternsType,
  colorTypes,
} from '../JSSThemeDefault';
import { applyOnlyDesktop, getButtonColors } from '../jss-helpers';
import { JSSThemeHeader22 } from './JSSThemeHeader22';
import { JSSThemeSportsMenu22 } from './JSSThemeSportsMenu22';
import { JSSThemeHeaderMenu22 } from './JSSThemeHeaderMenu22';
import { JSSThemeAuthForm22 } from './JSSThemeAuthForm22';
import { JSSThemeBalance22 } from './JSSThemeBalance22';
import { JSSThemeBetslip22 } from './JSSThemeBetslip22';
import { JSSThemeMyBets22 } from './JSSThemeMyBets22';
import { JSSThemeForm22 } from './JSSThemeForm22';
import { JSSThemeBannerItems22 } from './JSSThemeBannerItems22';
import { JSSThemeHeaderMobile22 } from './JSSThemeHeaderMobile22';
import { JSSThemeBonuses22 } from './JSSThemeBonuses22';
import { JSSThemeOutcomes22 } from './JSSThemeOutcomes22';
import { JSSThemeCoupons22 } from './JSSThemeCoupons22';
import { JSSThemeCasino22 } from './JSSThemeCasino22';
import { JSSThemePopularLeagues22 } from './JSSThemePopularLeagues22';
import { JSSThemeSearch22 } from './JSSThemeSearch22';
import { JSSThemeLive22 } from './JSSThemeLive22';
import { JSSThemeAccountSection22 } from './JSSThemeAccountSection22';
import { JSSThemePromotions22 } from './JSSThemePromotions22';
import { JSSThemeSitemap22 } from './JSSThemeSitemap22';
import { JSSThemeOryx22 } from './JSSThemeOryx22';
import { JSSThemeAffiliates22 } from './JSSThemeAffiliates22';

export const colorTypes22: ColorTypes = {
  ...colorTypes,
  primary: '#a20000',
  text1: '#666666',
  text2: '#19191a',
  text3: '#6e6e6e',
  text4: '#e5ebf0',
  text5: '',
  background1: '#3d6d99',
  background2: '#e8f0fe',
  background3: '#b9bcc3',
  background4: '#182d3d',
  background5: '#19191a',
  background6: '#19191a', //available for a new color
  shadow: 'rgba(0, 0, 0, 0.4)',
  accent: '#cc0001',
  success: '#14a33c',
  error: '#b33322',
  error2: '#b33322',
  error3: '#b33322',
  warning: '#ff6308',
  inform: '#8ecb65',
  neutral1: '#1d1d1c',
  neutral2: '#042048',
  neutral3: '#2c4761',
  neutral4: '#374e60',
  border1: '#3d6d99',
  border2: '#add8e6',
};

const pick = ({
  primaryBg2,
  whitePrimary,
  text2Primary,
  text2White,
  text1Bg2,
  text2Bg2,
  whiteBg2,
  whiteBg3,
  text2Neutral2,
  whiteSuccess,
  whiteAccent,
  whiteInform,
  whiteNeutral1,
  whiteNeutral2,
  whiteBg4,
  whiteText2,
  whiteError,
  text1Bg1,
  text1White,
  bg1Primary,
  whiteBg1,
  text2Bg3,
}) => ({
  primaryBg2,
  whitePrimary,
  text2Primary,
  text2White,
  text1Bg2,
  text2Bg2,
  whiteBg2,
  whiteBg3,
  text2Neutral2,
  whiteNeutral2,
  whiteSuccess,
  whiteAccent,
  whiteInform,
  whiteNeutral1,
  whiteBg4,
  whiteText2,
  whiteError,
  text1Bg1,
  text1White,
  bg1Primary,
  whiteBg1,
  text2Bg3,
});

export const colorCombinations22 = getColorCombinations(colorTypes22, pick);

export const JSSThemeVariables22: JSSThemeVariablesShapeType = {
  fonts: {
    default: {
      name: 'osg-roboto',
      fontFamily: 'osg-roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
      config: [
        {
          path: 'roboto/roboto-regular-webfont',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
        {
          path: 'roboto/roboto-italic-webfont',
          fontWeight: 'normal',
          fontStyle: 'italic',
        },
        {
          path: 'roboto/roboto-bold-webfont',
          fontWeight: 'bold',
          fontStyle: 'normal',
        },
        {
          path: 'roboto/roboto-bolditalic-webfont',
          fontWeight: 'bold',
          fontStyle: 'italic',
        },
        {
          path: 'roboto/roboto-black-webfont',
          fontWeight: '900',
          fontStyle: 'normal',
        },
        {
          path: 'roboto/roboto-blackitalic-webfont',
          fontWeight: '900',
          fontStyle: 'italic',
        },
        {
          path: 'roboto/roboto-light-webfont',
          fontWeight: '200',
          fontStyle: 'normal',
        },
      ],
    },
  },
  colors: {
    colorTypes: colorTypes22,
    colorCombinations: colorCombinations22,
  },
};

export const JSSTheme22: JSSThemeShapeType = themeVars => {
  const {
    colors: {
      colorCombinations: {
        whiteAccent,
        whitePrimary,
        whiteBg4,
        text2Bg2,
        whiteBg1,
      },
      colorTypes: {
        border2,
        background2,
        background4,
        black,
        white,
        primary,
        text2,
        text4,
      },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    Heading: {
      common: {
        background: text4,
        padding: [margins.md, margins.xm, margins.xs],
        margin: [0, margins.xs],
      },
    },
    affiliates: JSSThemeAffiliates22(themeVars),
    headerMenu: JSSThemeHeaderMenu22(themeVars),
    sportsMenu: JSSThemeSportsMenu22(themeVars),
    authForm: JSSThemeAuthForm22(themeVars),
    bonuses: JSSThemeBonuses22(themeVars),
    header: JSSThemeHeader22(themeVars),
    balance: JSSThemeBalance22(themeVars),
    betslip: JSSThemeBetslip22(themeVars),
    mybets: JSSThemeMyBets22(themeVars),
    outcomes: JSSThemeOutcomes22(themeVars),
    form: JSSThemeForm22(themeVars),
    banners: JSSThemeBannerItems22(themeVars),
    headerMobile: JSSThemeHeaderMobile22(themeVars),
    coupons: JSSThemeCoupons22(themeVars),
    casino: JSSThemeCasino22(themeVars),
    popularLeagues: JSSThemePopularLeagues22(themeVars),
    search: JSSThemeSearch22(themeVars),
    live: JSSThemeLive22(themeVars),
    accountSection: JSSThemeAccountSection22(themeVars),
    oryx: JSSThemeOryx22(themeVars),
    LiveCalendar: {
      common: {
        margin: '0',
      },
      dateTitle: {
        color: black,
      },
      dateEvent: {
        color: black,
        background: white,
        '&:hover': {
          color: black,
        },
      },
      link: {
        color: black,
      },
    },
    Button: {
      root: {
        borderRadius: '2px',
        fontWeight: 'bold',
      },
      default: getButtonColors(whiteAccent),
      primary: getButtonColors(whitePrimary),
      secondary: getButtonColors(whiteBg1),
      accent: getButtonColors(whiteAccent),
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
      linkAccent: {
        color: white,
        ...applyOnlyDesktop({
          '&:hover': {
            color: border2,
          },
        }),
      },
    },
    NoBetsMessage: {
      root: {
        ...text2Bg2,
      },
    },
    IconCircle: {
      root: {
        background: primary,
      },
    },
    BetslipMyBetsPanelTitle: {
      heading: {
        boxShadow: 'none',
        ...whiteBg4,
      },
    },
    BetslipMyBetsStakePotentialTotalUI: {
      stakePotential: {
        color: text2,
      },
      stakePotentialWrapper: {
        border: `1px solid ${background4}`,
        borderTop: 'none',
        background: white,
        marginTop: 0,
        padding: margins.md,
      },
      stakeTitle: {
        marginTop: 0,
      },
    },
    BetslipMyBetsPanelUI: {
      panel: {
        margin: 0,
        background: background2,
        borderRadius: 0,
      },
      singleSelectionWrapper: {
        borderTop: 'none',
        marginTop: 0,
        paddingTop: 0,
      },
    },
    promotions: JSSThemePromotions22(themeVars),
    sitemap: JSSThemeSitemap22(themeVars),
  };
};

export const getJSSThemePatterns22: GetJSSThemePatternsType = themeVars => {
  const {
    colors: {
      colorCombinations: { whitePrimary, whiteAccent, whiteBg3 },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    buttons: {
      primary: getButtonColors(whitePrimary),
      secondary: getButtonColors(whiteBg3),
      accent: getButtonColors(whiteAccent),
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
    },
  };
};
