// @flow
import { LoadableComponentWrapper } from '../../components/components/LoadableComponentWrapper';
import { createKoltronApp } from '../createKoltronApp';
import {
  JSSTheme22,
  JSSThemeVariables22,
  getJSSThemePatterns22,
} from './JSSTheme22';
import { GeoblockPage22 } from './GeoblockPage22';
// $FlowIgnore
import '../../../styles/application-koltron.scss';

createKoltronApp({
  DesktopComponent: LoadableComponentWrapper({
    loader: () =>
      import('./FullDesktop22').then(module => module.FullDesktop22),
  }),
  MobileComponent: LoadableComponentWrapper({
    loader: () => import('./FullMobile22').then(module => module.FullMobile22),
  }),
  JSSTheme: JSSTheme22,
  JSSThemeVariables: JSSThemeVariables22,
  JSSThemePatterns: getJSSThemePatterns22,
  GeoblockPage: GeoblockPage22,
});
